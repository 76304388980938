import React from "react";

import "./hbody.css";

const HiddenBody = () => {
    document.addEventListener("scroll", (e) => {
        const text_top = document.querySelector(".bait");

        if (document.documentElement.scrollTop < window.innerHeight / 2) {
            text_top.classList.add("bounce");
            text_top.classList.remove("top_title");
            text_top.textContent = "Scroll More";
        } else {
            text_top.classList.remove("bounce");
            text_top.classList.add("top_title");
            text_top.textContent = "Portfolio";
        }
    });

    return (
        <div className="HiddenBody">
            <div className="top-header">
                <a className="linked" href="#Experience">
                    <p className="bait bounce">Scroll More</p>
                </a>
                <div className="expereince" id="Experience">
                    <h2>Experience</h2>
                    <div className="job">
                        <div className="job-title">
                            <h3>Siemens</h3>
                            <h4>Software Engineer</h4>
                        </div>
                        <span className="line"></span>
                        <div className="job-desc">
                            Software Engineering on a range of different
                            Projects from internal tooling for storing to
                            correctly ‘life-cycling’ Engineering changes to C++
                            software for an on-train network security system and
                            working on a Train Sim-Tool architecture redesign
                            applying industry standards and allowing for better
                            future development. Other Projects have included
                            small automation tooling for people to improve their
                            personal work-flows
                        </div>
                    </div>
                    <div className="job">
                        <div className="job-title">
                            <h3>Royal Holloway, University Of London</h3>
                            <h4>Software Engineering Degree</h4>
                        </div>
                        <span className="line"></span>
                        <div className="job-desc">
                            I went to University for 4 years (Including my Year-In-Industry). 
                            Software Development Project — In first year, university, we had an invite only extra module which would replace one of the normal ones. On this module, we had a 5-person project where we had been given 12 user stories, a brief and some uml diagrams of the software. We decided to use Python Django with Rest API, JWT and MySQL backend with Reast.js front end. 
                        </div>
                    </div>
                </div>
                <div className="container">
                    <h2>Projects</h2>
                    <div className="projects">
                        <div className="card">
                            <span className="card-icon">
                                <img src="/traffic.png" width={150}></img>
                            </span>
                            <div className="tech">
                                <span className="pill">React</span>
                                <span className="pill">Express.js</span>
                                <span className="pill">RestAPI</span>
                                <span className="pill">Chart.js</span>
                            </div>
                            <span className="header-title">
                                Train Traffic Calulator
                            </span>
                            <span className="desc">
                                This was an internal tool i developed at Siemens (which is now on my Github) that Takes any two Train Stations and generates a graph of the amount of trains found between them two stations at any given point of the day
                            </span>
                        </div>
                        <div className="card">
                            <span className="card-icon">
                                <img src="/train.png" width={150}></img>
                            </span>
                            <div className="tech">
                                <span className="pill">Python</span>
                                <span className="pill">Tkinter</span>
                                <span className="pill">Multi-Threading</span>
                            </div>
                            <span className="header-title">
                                Train Simulation Tool Re-Architecture
                            </span>
                            <span className="desc">
                                Whilst at Siemens, I worked on was re-designing the architecture of a
                                train sim-tool in order to allow for
                                better performance and future development. The
                                original tool was largely a single threaded,
                                linearly coded application which caused a lot of
                                hard-to-read files, difficulty in debugging and
                                zero testing. In my re-design of the platform, I
                                introduced an MVC pattern to avoid mixing the
                                logic and the ‘UI’, developed interfaces for the
                                Model and view to allow for easy development changes
                            </span>
                        </div>
                        <div className="card">
                            <span className="card-icon">
                                <img src="/management.png" width={150}></img>
                            </span>
                            <div className="tech">
                                <span className="pill">.Net</span>
                                <span className="pill">SQL</span>
                                <span className="pill">MS-Access</span>
                                <span className="pill">C#</span>
                            </div>
                            <span className="header-title">
                                Business Management Application
                            </span>
                            <span className="desc">
                                My Business management tool was a .Net, C# desktop applicaiton that i made in 2021 for my College NEA Project. The entire tool is still avaliable on my github although there's a lot of set-up needed to run it. The main aim of the tool was to manage IT Auditing and Consulting projects from a business standpoint
                            </span>
                        </div>
                        <div className="card">
                            
                            <span className="card-icon">
                                <img src="/standards.png" width={150}></img>
                            </span>
                            <div className="tech">
                                <span className="pill">React</span>
                                <span className="pill">Serverless</span>
                                <span className="pill">AWS</span>
                                <span className="pill">S3</span>
                                <span className="pill">DynamoDB</span>
                            </div>
                            <span className="header-title">
                                Standards Smart Catalogue
                            </span>
                            <span className="desc">
                                Internal Searching tool for smart searching through the RSSB Standards Catalogue
                            </span>
                        </div>
                    </div>
                </div>

                <div className="Contacts">
                    <div className="col">
                        <h2>Contact Me</h2>
                        <a
                            className="linked"
                            href="https://www.github.com/joshuaBarrass"
                        >
                            <p>Github</p>
                        </a>
                        <a
                            className="linked"
                            href="https://www.linkedin.com/in/joshbarrass/"
                        >
                            <p>Linkedin</p>
                        </a>
                        <a
                            className="linked"
                            href="https://github.com/JoshuaBarrass/JoshuaBarrass/blob/main/Resume%20v2%20-%2027_03_2024.docx"
                        >
                            <p>CV</p>
                        </a>
                    </div>
                    <div className="col">
                        <span className="line-long"></span>
                    </div>
                    <div className="col">
                        <a
                            className="linked"
                            href="mailto:joshua.barrass@jbarrass.dev"
                        >
                            <p>Email: Joshua.Barrass@jbarrass.dev</p>
                        </a>
                        <a className="linked" href="tel:+447300236332">
                            <p>Tel: +447300236332</p>
                        </a>
                        <p></p>
                    </div>
                </div>
                <p>
                    © Copyright Joshua Barrass 2024 | Design by Joshua Barrass
                </p>
            </div>
        </div>
    );
};

export default HiddenBody;
