

import React from 'react';

import './infoText.css';

const InfoText = () => {

    return (
        <div className='info'>
            Software Engineer 
        </div>
    )
}

export default InfoText;