import React from 'react';

import './background.css'

const Background = () => {

    const softwareEngineeringSkills = [
        "Full-stack development",
        "Frontend development",
        "Backend development",
        "Desktop application development",
        "Web development",
        "Database management",
        "Cloud computing",
        "DevOps",
        "UI/UX design",
        "Software architecture design",
        "Algorithm design and analysis",
        "C++ programming",
        "Python programming",
        "Java programming",
        "JavaScript programming",
        "Objective-C programming",
        "Version control",
        "Testing ",
        "Agile methodologies",
        "Scrum practices",
        "CI/CD",
        "Docker",
        "Microservices architecture",
        "RESTful API design",
        "Security practices (encryption, authentication, etc.)",
        "Performance optimization",
        "Debugging and troubleshooting",
        "Technical documentation",
        "Problem-solving skills",
        "Team collaboration and communication",
    ];

    return (
        <div className='background'>
            <div className='bg-img'></div>
            <div className='row scrollright'>
                {softwareEngineeringSkills.map((e, index) => {
                    if(index % 3 === 0){
                        return(<p id={index} className='Skill'>{e.toUpperCase()}</p>)
                    }
                    else{
                        return(<span className='line'></span>)
                    }
                })}
                <p className='Skill'>{"Full-stack development".toUpperCase()}</p>
            </div>
            <div className='row reversed'>
            {softwareEngineeringSkills.map((e, index) => {
                    if(index % 3 === 1){
                        return(<p id={index} className='Skill'>{e.toUpperCase()}</p>)
                    }
                    else{
                        return(<span className='line'></span>)
                    }
                })}
                <p className='Skill'>{"Frontend development".toUpperCase()}</p>
            </div>
            <div className='row scrollright2'>
            {softwareEngineeringSkills.map((e, index) => {
                    if(index % 3 === 2){
                        return(<p id={index} className='Skill'>{e.toUpperCase()}</p>)
                    }
                    else{
                        return(<span className='line'></span>)
                    }
                })}
                <p className='Skill'>{"Backend development".toUpperCase()}</p>
            </div>
        </div>
    )
}

export default Background;