import './App.css';
import TitleTile from './components/title-Tile/titileTile';
import Cursor from './components/fake cursor/cursor';
import InfoText from './components/InfoText/infoTex';
import HiddenBody from './components/hidden body/hiddenbody';
import Background from './components/background/background';


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Cursor />
      </header>
      <body>
        <div className='title-container magicpattern'>
          <span className='circle'></span>
          <span className='circle'></span>
          <span className='circle'></span>
          <div>
            <TitleTile />
            <InfoText />
          </div>

        </div>


        <HiddenBody />
        <Background />
      </body>
    </div>
  );
}

export default App;
