
import React from "react";

import { useEffect, useState } from "react";

import "./titleTile.css";

function TitleTile() {

    const [displayText, setDisplayText] = useState([]);
    const [originalText, setOriginalText] = useState('JOSHUA BARRASS');

    const getRandomString = (length) => {
        let randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789<>/?;:@#=+-098*7&65%4$3£21!';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
        }
        return result;
    }

    useEffect(() => {

        // get some random string

        // every 100ms, change the text to a random string

        var count = -3;
        var speedCount = 0;

        var stringLength = originalText.length;

        setInterval(() => {

            var diff = stringLength - count;

            if (count < 0) {
                diff = stringLength + count;
            }

            if(speedCount % 3 === 0)
                count++;

            var splitOriginal = originalText.substring(0, count);

            var front = <span className="">{splitOriginal}</span>;
            var back = <span className="red">{getRandomString(diff)}</span>;

            setDisplayText([front, back]);

            if (diff > 0) {
                clearInterval();
            }

            speedCount++;

        }, 80);



    } ,[originalText])

    return (

        <div className="nameTileText">
            <h1>
                <span className="name">
                    {displayText.map((element) => {return element;})}
                </span>
            </h1>
        </div>

    );
}

export default TitleTile;