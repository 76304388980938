
import React, { useState, useEffect } from 'react';

import './cursor.css';

const Cursor = () => {

    const cursorSize = 20;
    const cursorHalf = cursorSize / 2;
    
    useEffect(() => {
        const cursor = document.querySelector('.cursor');
        const bluredFollower = document.querySelector('.blured-follower');
        
        cursor.setAttribute("style", "top: 50%; left: 50%;")
        bluredFollower.setAttribute("style",  "top: 50%; left: 50%;")

        document.addEventListener('mousemove', e => {
            cursor.setAttribute("style", "top: "+(e.pageY - document.documentElement.scrollTop)+"px; left: "+(e.pageX)+"px;")
            bluredFollower.setAttribute("style", "top: "+(e.pageY - document.documentElement.scrollTop)+"px; left: "+(e.pageX)+"px;")
        })

        document.addEventListener('mousedown', (e) => {
            cursor.classList.add("expand");
            cursor.setAttribute("style", "top: "+(e.pageY - document.documentElement.scrollTop)+"px; left: "+(e.pageX)+"px;")
        })

        document.addEventListener('mouseup', (e) => {
            cursor.classList.remove("expand");
            cursor.setAttribute("style", "top: "+(e.pageY - document.documentElement.scrollTop)+"px; left: "+(e.pageX)+"px;")
        })
    }, );
    return(
    <>
        <span className="cursor"></span>
        <span className="blured-follower"></span>
    </>
    )
};

export default Cursor;